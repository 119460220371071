module.exports = [{
      plugin: require('U:/Users/dave/Documents/a_proj/maqro/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#d2a","theme_color":"#f00","display":"minimal-ui","icon":"src/images/icon-resized-#0F0.png"},
    },{
      plugin: require('U:/Users/dave/Documents/a_proj/maqro/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Anton","Coda Caption","Faster One","Quicksand","Lexend Exa"]}},
    },{
      plugin: require('U:/Users/dave/Documents/a_proj/maqro/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
