// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("U:\\Users\\dave\\Documents\\a_proj\\maqro\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("U:\\Users\\dave\\Documents\\a_proj\\maqro\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("U:\\Users\\dave\\Documents\\a_proj\\maqro\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("U:\\Users\\dave\\Documents\\a_proj\\maqro\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pup-2-js": () => import("U:\\Users\\dave\\Documents\\a_proj\\maqro\\src\\pages\\pup2.js" /* webpackChunkName: "component---src-pages-pup-2-js" */)
}

